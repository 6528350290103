var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showWrapper)?_c('div',{staticClass:"relative z-10",attrs:{"aria-labelledby":"slide-over-title","role":"dialog","aria-modal":"true"}},[_c('Transition',{attrs:{"name":"bg-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSlideOver),expression:"showSlideOver"}],staticClass:"fixed inset-0 bg-black bg-opacity-75",on:{"click":function($event){$event.preventDefault();}}})]),_vm._v(" "),_c('div',{staticClass:"fixed inset-0 overflow-hidden"},[_c('div',{staticClass:"absolute inset-0 overflow-hidden"},[_c('div',{class:[
					_vm.enterDirection,
					'pointer-events-none fixed inset-y-0 flex max-w-full'
				]},[_c('Transition',{attrs:{"name":`slide-${_vm.slideDirection}`}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSlideOver),expression:"showSlideOver"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleHideButtonClick),expression:"handleHideButtonClick"}],staticClass:"w-screen pointer-events-auto md:max-w-118"},[_c('div',{staticClass:"flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"},[_c('div',{staticClass:"flex flex-col flex-1 min-h-0 overflow-y-auto border-b border-gray-100"},[_c('div',{staticClass:"sticky top-0 z-10 px-4 py-6 bg-gray-100 md:px-6"},[_vm._t("header",function(){return [_c('div',{staticClass:"flex items-start justify-between"},[_c('h2',{class:[
													{'order-last': _vm.slideDirection === 'left'},
													'text-xl font-bold text-black'
												],attrs:{"id":"slide-over-title"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.title)+"\n\t\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{class:[
													{'order-first': _vm.slideDirection === 'left'},
													'ml-3 flex h-7 items-center'
												]},[_c('button',{staticClass:"flex items-center justify-center w-8 h-8 text-black transition-all duration-100 bg-transparent rounded-full ring-offset-2 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300",attrs:{"type":"button"},on:{"click":_vm.handleHideButtonClick}},[_c('span',{staticClass:"sr-only"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tClose panel\n\t\t\t\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('CloseIcon')],1)])])]},{"handleHideButtonClick":_vm.handleHideButtonClick})],2),_vm._v(" "),_c('div',{staticClass:"relative flex-1 bg-white"},[_vm._t("body",null,{"handleHideButtonClick":_vm.handleHideButtonClick})],2)]),_vm._v(" "),(_vm.showFooter)?_vm._t("footer",function(){return [_c('div',{staticClass:"flex justify-end flex-shrink-0 px-4 py-4"},[_c('button',{staticClass:"px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2",attrs:{"type":"button"},on:{"click":_vm.handleHideButtonClick}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('button',{staticClass:"inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2",attrs:{"type":"submit"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\tSave\n\t\t\t\t\t\t\t\t\t")])])]},{"handleHideButtonClick":_vm.handleHideButtonClick}):_vm._e()],2)])])],1)])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }