<template>
	<WwSlideOver
		:slide-over-id="CARTS_SLIDE_OVER"
		title="Your Cart"
		:slide-direction="slideDirection"
		:show-footer="false"
	>
		<template #header="{ handleHideButtonClick }">
			<div class="relative flex items-start justify-between h-7">
				<div class="flex items-center xs:items-start space-x-2 h-full xs:-mt-0.5">
					<h2
						:class="[
							'xs:text-xl text-2xl font-bold text-black mr-2 self-end flex flex-nowrap'
						]"
					>
						Your Cart
					</h2>
				</div>

				<div class="flex items-center ml-3 h-7">
					<button
						type="button"
						class="flex items-center justify-center w-8 h-8 text-black bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
						@click="handleHideButtonClick"
					>
						<CloseIcon />
					</button>
				</div>
			</div>
		</template>
		<template #body>
			<template v-if="hasCarts">
				<CartDropdownCard
					v-for="cart in carts"
					:key="cart.id"
					:cart="cart"
					class="pr-12"
				/>
			</template>
			<template v-else>
				<div class="flex flex-col items-center justify-center py-24">
					<div class="py-12 text-2xl font-bold text-black">
						Your cart is empty
					</div>
					<img
						src="@/assets/empty-states/no-carts.svg"
						alt="No carts"
					>
					<div class="max-w-xs p-4 text-sm font-normal text-gray-500">
						Once product has been added you will be able to edit the details of your order.
					</div>
				</div>
			</template>
		</template>
	</WwSlideOver>
</template>

<script async>
import { mapGetters } from 'vuex'

import CartDropdownCard from '@/components/cart/CartDropdownCard.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import WwSlideOver from '@/components/UI/WwSlideOver.vue'
import { CARTS_SLIDE_OVER } from '@/constants/slideOver/names.js'

export default {
	components: {
		CartDropdownCard,
		CloseIcon,
		WwSlideOver
	},
	props: {
		carts: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			CARTS_SLIDE_OVER
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		slideDirection() {
			if (this.hasCarts && this.isMobile) return 'left'
			return 'right'
		},
		hasCarts() {
			return !!this.carts?.length
		}
	}
}
</script>
