<template>
	<div
		v-if="showWrapper"
		class="relative z-10"
		aria-labelledby="slide-over-title"
		role="dialog"
		aria-modal="true"
	>
		<Transition name="bg-fade">
			<div
				v-show="showSlideOver"
				class="fixed inset-0 bg-black bg-opacity-75"
				@click.prevent
			/>
		</Transition>

		<div class="fixed inset-0 overflow-hidden">
			<div class="absolute inset-0 overflow-hidden">
				<div
					:class="[
						enterDirection,
						'pointer-events-none fixed inset-y-0 flex max-w-full'
					]"
				>
					<Transition :name="`slide-${slideDirection}`">
						<div
							v-show="showSlideOver"
							v-click-outside="handleHideButtonClick"
							class="w-screen pointer-events-auto md:max-w-118"
						>
							<div class="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
								<div class="flex flex-col flex-1 min-h-0 overflow-y-auto border-b border-gray-100">
									<div class="sticky top-0 z-10 px-4 py-6 bg-gray-100 md:px-6">
										<slot
											name="header"
											:handle-hide-button-click="handleHideButtonClick"
										>
											<div class="flex items-start justify-between">
												<h2
													id="slide-over-title"
													:class="[
														{'order-last': slideDirection === 'left'},
														'text-xl font-bold text-black'
													]"
												>
													{{ title }}
												</h2>

												<div
													:class="[
														{'order-first': slideDirection === 'left'},
														'ml-3 flex h-7 items-center'
													]"
												>
													<button
														type="button"
														class="flex items-center justify-center w-8 h-8 text-black transition-all duration-100 bg-transparent rounded-full ring-offset-2 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
														@click="handleHideButtonClick"
													>
														<span class="sr-only">
															Close panel
														</span>
														<CloseIcon />
													</button>
												</div>
											</div>
										</slot>
									</div>
									<div class="relative flex-1 bg-white">
										<slot
											name="body"
											:handle-hide-button-click="handleHideButtonClick"
										/>
									</div>
								</div>

								<slot
									v-if="showFooter"
									name="footer"
									:handle-hide-button-click="handleHideButtonClick"
								>
									<div class="flex justify-end flex-shrink-0 px-4 py-4">
										<button
											type="button"
											class="px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
											@click="handleHideButtonClick"
										>
											Cancel
										</button>
										<button
											type="submit"
											class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
										>
											Save
										</button>
									</div>
								</slot>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapMutations, mapState } from 'vuex'

import CloseIcon from '@/components/icons/CloseIcon.vue'

export default {
	components: {
		CloseIcon
	},
	props: {
		slideOverId: {
			type: String,
			required: true
		},
		hideClosesSlideOver: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			default: 'Panel title'
		},
		slideDirection: {
			type: String,
			default: 'right',
			validator(value) {
				return [
					'left',
					'right'
				].includes(value)
			}
		},
		showFooter: {
			type: Boolean,
			default: true
		}
	},
	emits: [
		'close-prevented',
		'ok',
		'hide'
	],
	data() {
		return {
			showWrapper: false,
			showSlideOver: false
		}
	},
	computed: {
		...mapState('slideOver', [
			'isOpen',
			'activeSlideOverId',
			'closePrevented'
		]),
		enterDirection() {
			switch (this.slideDirection) {
				case 'left':
					return 'left-0 md:pr-10'
				case 'right':
					return 'right-0 md:pl-10'
				default:
					return 'right-0 md:pl-10'
			}
		}
	},
	watch: {
		activeSlideOverId: {
			immediate: true,
			handler() {
				this.toggleShowState(this.isOpen)
			}
		}
	},
	methods: {
		...mapMutations('slideOver', [ 'closeSlideOver' ]),
		toggleShowState(isOpen) {
			if (isOpen && this.slideOverId === this.activeSlideOverId) {
				this.showWrapper = true
				this.$nextTick(() => {
					this.showSlideOver = true
				})
			} else {
				this.showSlideOver = false
				setTimeout(() => {
					this.showWrapper = false
				}, 300)
			}
		},
		handleHideButtonClick(event) {
			this.$emit('hide')
			if (this.hideClosesSlideOver) this.handleClose(event)
		},
		handleClose(event) {
			event.stopPropagation()
			this.closePrevented ? this.$emit('close-prevented') : this.closeSlideOver()
		},
		handleOk(event) {
			event.stopPropagation()
			this.$emit('ok')
		}
	}
}
</script>
