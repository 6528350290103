<template>
	<div
		class="flex items-center justify-between w-full p-4 mx-4"
		:class="{ 'border-t border-gray-200': cart.index !== 0 }"
	>
		<div class="font-bold text-left">
			<div>
				{{ name }} ({{ cart.numItems }})
			</div>
			<button
				class="text-sm text-green-500"
				@click="showCart"
			>
				View this cart
			</button>
		</div>
		<div class="whitespace-nowrap">
			Cart: <span class="font-bold">
				${{ subTotal }}
			</span>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'

import { CART_SLIDE_OVER } from '@/constants/slideOver/names.js'

export default {
	props: {
		cart: {
			type: Object,
			required: true
		}
	},
	emits: [ 'close-drop-down' ],
	data() {
		return {
			CART_SLIDE_OVER
		}
	},
	computed: {
		name() {
			return this.cart.listing?.name || ''
		},
		subTotal() {
			return this.cart.subTotal?.toFixed(2) || 0
		}
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver', 'closeSlideOver' ]),
		...mapMutations('cart', [ 'updateTemporaryCartData' ]),
		showCart() {
			this.updateTemporaryCartData({
				items: this.cart.items?.map(item => {
					return {
						...item,
						product_id: item.productId
					}
				}) || [],
				num_items: this.cart.numItems,
				subtotal: this.cart.subTotal,
				name: this.cart.listing?.name,
				logo: this.cart.listing?.logo?.fileName,
				url: this.cart.listing?.url,
				listingId: this.cart.listing?.id,
				settings: this.cart.listing?.settings
			})
			this.showSlideOver(CART_SLIDE_OVER)
			this.$emit('close-drop-down')
		}
	}
}
</script>
